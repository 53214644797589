const metaDescription = {
  wherewego: '',
  skillup:
    'Our non-profit connects workers with the right tools, resources and support to make confident career shifts. We’ll help you find career training, good wage jobs and opportunities for promising career growth.',
};
const metaTitle = {
  wherewego: 'WhereWeGo Training Navigator x GNO Inc CareerGuide',
  skillup: 'SkillUp Coalition',
};
const publisher = {
  wherewego: '',
  skillup: 'https://www.facebook.com/SkillUpCoalition',
};
const ogImage = {
  wherewego: '',
  skillup:
    'https://res.cloudinary.com/wherewego/image/upload/v1666738713/skillup/f58on9gb2dl2kampq6ed.jpg',
};

const twitter = {
  wherewego: '',
  skillup: '@SkillUpOrg',
};
export { metaDescription, metaTitle, ogImage, publisher, twitter };
