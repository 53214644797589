import Head from 'next/head';
import faviconPath from '../faviconPath';
import frontendURL from '../frontendURL';
import {
  metaDescription,
  metaTitle,
  ogImage,
  publisher,
  twitter,
} from '../meta';
import project from '../project';
import { isProduction } from '../projectEnv';

interface ProjectHeadProps { }

const ProjectHead: React.FC<ProjectHeadProps> = () => {
  return (
    <Head>
      <title>{metaTitle[project]}</title>
      <meta name="description" content={metaDescription[project]} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle[project]} />
      <meta property="og:description" content={metaDescription[project]} />
      <meta property="og:url" content={frontendURL} />
      <meta property="og:site_name" content={metaDescription[project]} />
      <meta property="article:publisher" content={publisher[project]} />
      <meta property="og:image" content={ogImage[project]} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={ogImage[project]} />
      <meta name="twitter:site" content={twitter[project]} />;
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link rel="icon" href={faviconPath} />
      {
        !isProduction &&
        <meta name="robots" content="noindex" />
      }

    </Head>
  );
};

export default ProjectHead;
