import project from "./project";
import projectEnv from "./projectEnv";

const config = {
  wherewego: {
    development: 'http://localhost:7777',
    staging: 'https://staging.wherewego.org',
    production: 'https://explore.wherewego.org',
  },
  skillup: {
    development: 'http://localhost:7777/',
    staging: 'https://staging.skillup.wherewego.org/',
    production: 'https://explore.skillup.org/',
  },
};

const frontendURL = config[project][projectEnv]

export default frontendURL;
